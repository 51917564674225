<template>
  <div>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="clientes"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pageable.totalElements"
        hide-default-footer
        dense
        locale="pt-BR"
        item-class="fonte"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Clientes</v-toolbar-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisa por nome"
              single-line
              hide-details
              class="px-10"
            ></v-text-field>
            <v-btn color="primary" dark class="square" @click="abreForm"
              ><v-icon>{{ mdiPlus }}</v-icon></v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.endereco`]="{ item }">
          {{ item.endereco.enderecoStr }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="primary darken-2"
            @click="editar(item)"
            title="Editar"
            >{{ mdiPencilBox }}</v-icon
          >
          <v-icon
            color="teal darken-2"
            @click="addUsuario(item)"
            title="Adicionar usuário"
            >{{ mdiAccountBox }}</v-icon
          >
          <v-icon
            color="error darken-2"
            @click="excluir(item)"
            title="Excluir"
            >{{ mdiCloseBox }}</v-icon
          >
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageable.page"
          :length="pageable.totalPages"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
      :fullscreen="mobile"
    >
      <v-card>
        <v-card-title
          >Cliente
          <v-spacer></v-spacer>

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ClienteForm
            v-if="dialog"
            @sucesso="inicializar"
            :payload="payload"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUsuario"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
      :fullscreen="mobile"
    >
      <v-card>
        <v-card-title
          >Usuário
          <v-spacer></v-spacer>

          <v-btn icon @click="dialogUsuario = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <UsuarioForm
            v-if="dialogUsuario"
            @sucesso="sucessoUsuario"
            :payload="payload"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "debounce";
import Cliente from "@/service/cliente";
import ClienteForm from "./Form";
import UsuarioForm from "@/views/usuario/Form";
import {
  mdiCloseBox,
  mdiPencilBox,
  mdiPlus,
  mdiFileDocumentEditOutline,
  mdiAccountBox,
} from "@mdi/js";
export default {
  name: "Cliente",
  components: {
    ClienteForm,
    UsuarioForm,
  },
  data() {
    return {
      sortBy: "nome",
      sortDesc: false,
      search: "",
      mdiCloseBox,
      mdiPencilBox,
      mdiPlus,
      mdiFileDocumentEditOutline,
      mdiAccountBox,
      loading: false,
      clientes: [],
      options: {},
      pageable: {},
      dialog: false,
      dialogUsuario: false,
      payload: {},
      headers: [
        {
          text: "Nome",
          value: "nome",
          align: "start",
          filterable: false,
          sortable: true,
        },
        {
          text: "Documento",
          value: "documento",
          filterable: false,
          sortable: true,
        },
        {
          text: "Telefone",
          value: "telefone",
          filterabldatae: false,
          sortable: true,
          width: 150,
        },
        {
          text: "Endereço",
          value: "endereco",
          filterabldatae: false,
          sortable: true,
        },
        {
          text: "Ações",
          value: "actions",
          filterabldatae: false,
          sortable: false,
          width: 110,
        },
      ],
    };
  },
  watch: {
    sortBy(sort) {
      if (sort == undefined) return;
      this.inicializar({ page: 0, sort });
    },
    sortDesc(val) {
      if (val == undefined) return;
      const sort = val ? "desc" : "asc";
      this.inicializar({ page: 0, sort: this.sortBy + `,${sort}` });
    },
    search(nome, novoNome) {
      if (nome != novoNome && novoNome.trim().length > 0) this.pesquisa(nome);
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    pesquisa: debounce(async function (nome) {
      this.inicializar({ page: 0, nome });
    }, 700),
    showRow(row, select) {
      console.log(row, select);
    },
    changePage(value) {
      const sort = this.sortDesc ? ",desc" : ",asc";
      this.pageable.page = value;
      this.inicializar({ page: value - 1, sort: this.sortBy + `${sort}` });
    },
    abreForm() {
      this.payload = {};
      this.dialog = true;
    },
    async inicializar(options) {
      this.loading = true;
      this.dialog = false;

      const resp = await Cliente.findAll({ ...options });
      this.clientes = resp.content;
      this.pageable = {
        totalElements: resp.totalElements,
        totalPages: resp.totalPages,
        pageSize: resp.size,
        page: resp.number + 1,
      };
      this.loading = false;
    },
    editar(item) {
      this.payload = item;

      this.$nextTick(() => (this.dialog = true));
    },
    addUsuario(item) {
      this.payload = item;
      this.$nextTick(() => (this.dialogUsuario = true));
    },
    sucessoUsuario() {
      this.payload = "";
      this.dialogUsuario = false;
    },
    async excluir(item) {
      try {
        await Cliente.delete(item);
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
        await this.inicializar();
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Ocorreu um erro ao excluir o cliente, já está associado ao projeto",
          type: "error",
        });
        console.log(error);
      }
    },
  },
  created() {
    this.inicializar({ page: 0, sort: "nome" });
  },
};
</script>

<style scoped>
.fonte {
  font-size: 0.5rem;
}
</style>
