<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="salvar">
    <v-row>
      <v-col cols="12" sm="8" md="8" lg="8">
        <v-text-field
          label="Nome"
          ref="nome"
          outlined
          dense
          v-model="form.nome"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Documento (CPF/CNPJ)"
          ref="documento"
          outlined
          dense
          v-model="form.documento"
          :rules="[rules.required]"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-text-field
          label="Telefone"
          ref="telefone"
          outlined
          dense
          v-model="form.telefone"
          :rules="[rules.required]"
          v-mask="['(##) #####-####']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="5" md="5" lg="5">
        <v-text-field
          label="Logradouro"
          ref="logradouro"
          outlined
          dense
          v-model="form.endereco.logradouro"
          :rules="[rules.required]"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" lg="2">
        <v-text-field
          label="Número"
          ref="numero"
          outlined
          dense
          v-model="form.endereco.numero"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" lg="2">
        <v-text-field
          label="Complemento"
          ref="complemento"
          outlined
          dense
          v-model="form.endereco.complemento"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Bairro"
          ref="bairro"
          outlined
          dense
          v-model="form.endereco.bairro"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Cidade"
          ref="cidade"
          outlined
          dense
          v-model="form.endereco.cidade"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="1" md="1" lg="1">
        <v-text-field
          label="UF"
          ref="uf"
          outlined
          dense
          v-model="form.endereco.uf"
          :rules="[rules.required, rules.min2]"
          v-mask="'XX'"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3" md="3" lg="3">
        <v-text-field
          label="CEP"
          ref="cep"
          outlined
          dense
          v-model="form.endereco.cep"
          :rules="[rules.required]"
          v-mask="['#####-###']"
        ></v-text-field>
      </v-col>

      <div class="d-flex justify-center" v-if="pf">
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-text-field
            label="Nacionalidade"
            ref="nacionalidade"
            outlined
            dense
            v-model="form.nacionalidade"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-text-field
            label="Estado Civil"
            ref="estadoCivil"
            outlined
            dense
            v-model="form.estadoCivil"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-text-field
            label="Profissao"
            ref="profissao"
            outlined
            dense
            v-model="form.profissao"
          ></v-text-field>
        </v-col>
      </div>
      <!-- <v-col cols="12" sm="8" md="8" lg="8">
        <v-text-field
          label="E-mail"
          ref="email"
          outlined
          dense
          v-model="form.usuario.email"
          :rules="[rules.email]"
          autocomplete="false"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Senha"
          outlined
          type="password"
          dense
          v-model="form.usuario.senha"
          autocomplete="new-password"
        ></v-text-field>
      </v-col> -->

      <v-col cols="12" align="right">
        <v-btn
          dark
          depressed
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Cliente from "@/service/cliente";
export default {
  name: "FormCliente",
  props: {
    payload: {
      type: Object,
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        nome: "",
        documento: "",
        telefone: "",
        endereco: {
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          uf: "",
          cep: "",
        },
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
        min2: (value) => value.length == 2 || "Obrigatório duas posições",
      },
    };
  },
  computed: {
    pf() {
      const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

      return pattern.test(this.form.documento);
    },
  },
  methods: {
    formToUpperCase() {
      if (!this.form) return;
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] && typeof this.form[f] == "string") {
          this.form[f] = this.form[f].toUpperCase();
        } else if (this.form[f] && typeof this.form[f] == "object") {
          Object.keys(this.form[f]).forEach((i) => {
            if (this.form[f][i] && typeof this.form[f][i] == "string")
              this.form[f][i] = this.form[f][i].toUpperCase();
          });
        }
      });
    },
    async salvar() {
      if (!this.$refs.form.validate()) return;

      this.formToUpperCase();
      //  usuario: {
      //       email:
      //         this.form.usuario &&
      //         this.form.usuario.email &&
      //         this.form.usuario.email.toLowerCase(),
      //       senha:
      //         this.form.usuario &&
      //         this.form.usuario.senha &&
      //         this.form.usuario.senha.toLowerCase(),
      //       perfis: [{ id: 2 }],
      //       bloqueado: false,
      //       verificado: true,
      //       id: this.form.usuario.id,
      //     },

      try {
        const payload = {
          ...this.form,
        };
        await Cliente.save(payload);

        this.$emit("sucesso");
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
      } catch (error) {
        let msg = "Ocorreu um erro ao salvar os dados!";
        if (error.title.includes("Violação")) {
          msg = "O documento já está cadastrado";
        }
        this.$notify({
          title: "Erro",
          text: msg,
          type: "error",
        });
      }
    },
  },
  created() {
    if (this.payload && this.payload.id) {
      this.form = {
        ...this.payload,
      };
    }
  },
};
</script>

<style scoped>
div input {
  text-transform: uppercase;
}
</style>
